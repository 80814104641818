<template>
  <q-card flat bordered class="q-mb-sm">
    <q-toolbar>
      <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
    </q-toolbar>
    <q-card-section class="q-pt-none row q-col-gutter-md">
      <q-form @submit.prevent="searchAdvertiserInvoices" class="row col-12 q-gutter-md">
        <div class="col-4">
          <select-request
            outlined
            endpoint="redirect?app=ADVERTISER&path=/api/get/advertiser"
            option-label="trademark"
            option-value="id"
            map-options
            label="Anunciante"
            clearable
            v-model="params.advertiser_id"
            @update="(value) => (params.advertiser_id = value === null ? null : value.id)"
          />
        </div>
        <div class="col-4">
          <q-input
            outlined
            label="CNPJ"
            :rules="[notRequiredIsCNPJ]"
            mask="##.###.###/####-##"
            unmasked-value
            v-model="params.cnpj"
          />
        </div>
        <div class="col-3">
          <q-input
            outlined
            label="Período"
            readonly
            v-model="params.period"
            mask="##/####"
            @click="$refs.qDateProxy.show()"
          >
            <template v-slot:append>
              <q-icon name="cancel" @click.stop="params.period = ''" class="cursor-pointer" />
            </template>
            <template v-slot:prepend>
              <q-icon name="event" class="cursor-pointer" color="primary">
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                  <q-date
                    mask="MM/YYYY"
                    minimal
                    emit-immediately
                    v-model="params.period"
                    default-view="Years"
                    clearable
                    @input="controllerDates"
                    :options="[{ view: ['Years', 'Months'] }]"
                  >
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
        <div class="col-12 text-right">
          <q-btn
            color="positive"
            @click="ActionSetDialogCreateAdvertiserInvoice(true)"
            label="Criar Faturamento"
            no-caps
            unelevated
            class="q-mr-md q-px-md"
          />
          <q-btn color="positive" type="submit" label="Buscar" no-caps unelevated class="q-px-md" />
        </div>
      </q-form>
    </q-card-section>
  </q-card>
</template>

<script>
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest';
import FinanceService from '@/services/FinanceService';
import FinancialMixin from '@/mixins/FinancialMixin.js';

export default {
  name: 'HeaderAdvertiserInvoice',
  components: { SelectRequest },
  mixins: [FinanceService, FinancialMixin],
  data() {
    return {
      countclickedDate: 0,
      params: {
        advertiser_id: null,
        cnpj: '',
        period: ''
      }
    };
  },

  created() {
    this.searchAdvertiserInvoices();
  },
  methods: {
    async searchAdvertiserInvoices() {
      try {
        this.onLoading(true);
        let filter = [];

        if (this.params.period != '') {
          var splitedDate = this.params.period.split('/');
          filter.push(`period%3D${splitedDate[1]}-${splitedDate[0]}-01`);
        }

        for (var key in this.params) {
          if (this.params[key] && key != 'period') filter.push(`${key}%3D${this.params[key]}`);
        }
        const { data, status } = await this.listAdvertiserInvoices(filter.join('%26'));
        if (status == 200) {
          this.ActionSetAdvertiserInvoices({ value: data });
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    controllerDates() {
      this.countclickedDate += 1;
      if (this.countclickedDate >= 2) {
        this.countclickedDate = 0;
        this.$refs.qDateProxy.hide();
      }
    }
  },

  watch: {
    //eslint-disable-next-line
    advertiserInvoiceUpdate: function (newVal, oldVal) {
      if (newVal) {
        this.searchAdvertiserInvoices();
        this.ActionSetAdvertiserInvoiceUpdate(false);
      }
    }
  }
};
</script>

<style>
.q-field--outlined.q-field--readonly .q-field__control:before {
  border-style: solid;
}
</style>
